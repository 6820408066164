<div class="aspect-auto flex h-screen w-screen">
    <div class="left-section">
        <div class="left-section-content">
            <div class="header text-4xl">
                Welcome!,
                <br>
                Sign-up to your account!
            </div>
            <div class="my-2 text-base mt-4">Already have one?
                <a href="/login" class="theme-text font-medium">Login</a>
            </div>
            <div class="my-3">
                <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                    <div class="p-fluid ">
                        <div class="p-field">
                            <div class="input hover:bg-slate-100 cursor-pointer h-10 border rounded-md flex items-center justify-center">
                                <p-button styleClass=" p-3 rounded-xl" type="submit"
                                    label="Continue with Google"></p-button>
                                <img src="/icons/google-icon.svg" alt="google-icon">
                            </div>
                            <div
                                class="input hover:bg-slate-100  cursor-pointer h-10 border rounded-md mt-4 flex items-center justify-center">
                                <p-button styleClass=" p-3 rounded-xl" type="submit"
                                    label="Continue with Microsoft"></p-button>
                                <img src="/icons/microsoft.svg" alt="google-icon">
                            </div>

                            <div class="separator rounded-lg">or</div>

                            <label class="login-label" for="username ">Email</label>
                            <input id="email" class="input mt-2" type="email" pInputText formControlName="email"
                                placeholder="name@yourcompany.com">

                        </div>
                        <div class="mt-4">
                            <p-button styleClass="bg-arena-orange p-3 rounded-xl text-white" type="submit" label="Continue"
                                [disabled]="!signupForm.valid"></p-button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="w-[300px] text-slate-600  ml-16 mt-5 text-justify ">
                <p>By creating an account, you agree to our
                <p class="ml-4 mt-1"> <a class="underline" href=" https://inncircles.com/privacy-policy/" target="_blank">Terms of service</a> and <a class="underline" href="https://inncircles.com/privacy-policy/" target="_blank">privacy
                        Policy</a></p>
            </div>
        </div>

    </div>
    <div class="right-section">
        <!-- <div class="login-right-section">
            <div class="header text-center">
                <span class="theme-text">
                    A completely new way to
                    <br>
                    handle your legal documents
                </span>
            </div>
        </div> -->
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>