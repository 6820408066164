import { Component, Input } from '@angular/core';
import { CONTRACT_USER_ROLE, IUser } from '../../../../../@core/models/model';
import { ContractService } from '../../../../services/contracts.service';
import { UserService } from '../../../../services/user.service';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UserAvatarPipe } from '../../../../../@shared/pipes/user-avatar-pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ApprovalWorkflowComponent } from '../../../../../@shared/components/approval-workflow/approval-workflow.component';
import { FullNamePipe } from '../../../../../@shared/pipes/fullName.pipe';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastService } from '../../../../../@core/services/toast.service';
import { MultiSelectModule } from 'primeng/multiselect';


@Component({
  selector: 'app-share-doc',
  standalone: true,
  imports: [FormsModule, ButtonModule, DropdownModule, OverlayPanelModule, UserAvatarPipe, CheckboxModule, FormsModule, FullNamePipe, MultiSelectModule],
  templateUrl: './share-doc.component.html',
  styleUrl: './share-doc.component.scss'
})
export class ShareDocComponent {

  @Input() contractId: string = ""

  shareUsers: IUser[] = []
  sharedUsers: {
    user: IUser, role: string
  }[] = []
  selectedShareUser: { user: IUser, role: string }[] = [];
  connectedUsers$!: any
  currentUser: IUser | null | undefined
  docsShareOptions = ['Editor', 'Viewer', 'Commenter']

  constructor(
    private contractService: ContractService,
    private userService: UserService,
    private authService: AuthenticationService,
    private toast: ToastService
  ) {
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
    });
  }



  getShareUsers() {
    this.shareUsers = []
    this.userService.getUsers().subscribe(res => {
      this.shareUsers = res.data
      if (this.sharedUsers.length > 0) {
        this.shareUsers = this.shareUsers.filter((user: any) =>
          !this.sharedUsers.some((sharedUser: any) => sharedUser.user._id === user._id)
        );
      }
      this.shareUsers = this.shareUsers.filter((user: any) => user._id !== this.currentUser?._id)
      this.shareUsers = this.shareUsers.map((user: any) => {
        user.label = user.fullName ? user.fullName : user.firstName + ' ' + user.lastName
        return user
      })
    })
  }

  getSharedUsers() {
    this.sharedUsers = []
    this.contractService.getSharedUsers(this.contractId).subscribe(res => {
      if (res.data && res.data.users.length) {
        this.sharedUsers = res.data.users
        this.getShareUsers()
      }
    })
  }


  shareToUser($event: any) {
    this.selectedShareUser = []
    const users = $event.value
    users.map((user: any) => {
      this.selectedShareUser.push({ user: user, role: CONTRACT_USER_ROLE.VIEWER })
    })
  }

  sendInvite() {

    this.contractService.shareContract(this.contractId, this.selectedShareUser).subscribe((res) => {
      if (res.data) {
        this.getSharedUsers()
        this.toast.success("Sent Invite Successfully");
        this.selectedShareUser = []
      }
    })
  }
  changeDocRole($event: any, user: string, flag: boolean) {
    if (flag) {
      this.selectedShareUser = this.selectedShareUser.map((sharedUser: any) => {
        if (sharedUser.user._id === user) {
          sharedUser.role = $event.value
        }
        return sharedUser
      })
      return
    }

    this.contractService.changeDocRole(this.contractId, user, $event.value).subscribe((res) => {
      if (res.data) {
        this.toast.success("Role Changed Successfully");
        this.getSharedUsers()
      }
    })
  }

  deleteDocRole(userId: string) {
    this.selectedShareUser = this.selectedShareUser.filter((sharedUser: any) => sharedUser.user._id !== userId)
    this.contractService.deleteDocRole(this.contractId, userId).subscribe((res) => {
      if (res.data) {
        const deletedUser = this.shareUsers.find((shareUser: any) => shareUser._id === userId);

        if (deletedUser) {
          const userName = new FullNamePipe().transform(deletedUser)
          this.toast.success(`${userName} Was Deleted Successfully .`);
        };
      }
      this.getSharedUsers()
    });
    this.getSharedUsers()
  }

  Cancel(op: any) {
    op.hide();
  }
}










