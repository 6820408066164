import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from '../@core/services/base.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  apiUrl = environment.apiUrl + '/comments/'
  public isHighlight: boolean = false;
  public tabActiveIndex: number = 0;

  toggleIsHighlight() {
    this.isHighlight = !this.isHighlight;
  }

  constructor(
    protected override http: HttpClient,
    private router: Router
  ) {
    super(http);
  }

  getComments(id: string) {
    return this.get(this.apiUrl + 'comment', { id });
  }

  getAllComments() {
    return this.get<any>(this.apiUrl + 'all-comments');
  }

  getCommentById(id: string) {
    return this.get<any>(this.apiUrl + 'comment', { id });
  }

  addComment(id: string, comment: string) {
    const payload = {
      comment,
      auth: { id }
    };

    return this.post<any>(this.apiUrl + 'create-comment', payload);
  }

  replyToAComment(userId: string, comment: string, content: string) {
    const payload = {
      comment,
      content,
      auth: { id: userId }
    }
    return this.post<any>(this.apiUrl + 'reply-comment', payload);
  }
}
