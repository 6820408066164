@if(flowType == FLOW_TYPE.APPROVAL){
<p-timeline [value]="approvalConf.approvers">
    <ng-template pTemplate="content" let-approver>
        <div class="flex flex-row justify-between items-start ">
            <div class="flex flex-row justify-between  gap-2">
                <img [src]="approver.user | userAvatar" alt="User Avatar" class="w-8 h-8 rounded-full" />
                <div class="flex flex-col justify-between items-start">
                    <span class="font-medium ">{{approver.user.fullName ? approver.user.fullName :
                        approver.user.firstName + ' ' +
                        approver.user.lastName}}</span>
                    @if(approver.status === 'PENDING'){
                    <span class="text-gray-400 text-sm">Review Pending</span>
                    }
                    @if(approver.status === 'APPROVED'){
                    <span class="text-gray-400 text-sm">Approved on {{approver.modifiedAt | dateFormatter }}</span>
                    }
                    @if(approver.status === 'WAITING'){
                    <span class="text-gray-400 text-sm">waiting</span>
                    }
                    @if(approver.status === 'REJECTED'){
                    <span class="text-gray-400 text-sm">Rejected on {{approver.modifiedAt}}</span>
                    }
                </div>
            </div>
            <div class="status">
                <app-badge [approver]="approver"></app-badge>
                @if(approver.status === 'WAITING'){
                <p-button text="true" [disabled]="canEdit" icon="pi pi-trash" severity="danger"
                    (onClick)="deleteApprover(approver.user._id)"></p-button>
                }
            </div>
        </div>
    </ng-template>
</p-timeline>
}@else {
<p-timeline [value]="signatories">
    <ng-template pTemplate="content" let-signatory let-i="index">
        <div class="flex flex-row justify-between items-start ">
            <div class="flex flex-row justify-between  gap-2">
                <!-- <img [src]="signatory.email | emailAvatar" alt="Email Avatar" class="w-8 h-8 rounded-full" /> -->
                <div class="flex flex-col justify-between items-start">
                    <span class="font-medium ">{{signatory.name}}</span>
                    @if(signatory.status === 'PENDING'){
                    <span class="text-gray-400 text-sm">Review Pending</span>
                    }
                    @if(signatory.status === 'SIGNED'){
                    <span class="text-gray-400 text-sm">Signed</span>
                    }
                    @if(signatory.status === 'WAITING'){
                    <span class="text-gray-400 text-sm">Waiting</span>
                    }
                    @if(signatory.status === 'REJECTED'){
                    <span class="text-gray-400 text-sm">Rejected</span>
                    }
                </div>
            </div>
            <div>
                <app-badge [approver]="signatory"></app-badge>
                @if(signatory.status === 'WAITING' && (contractStatus == CONTRACT_STATUS.READY_FOR_SIGNATURE)){
                <p-button text="true" icon="pi pi-trash" severity="danger"
                    (onClick)="deleteSignatory(signatory.email)"></p-button>
                }
                @if(signatory.signedAttachment){
                <p-button icon="pi pi-download" class="border  p-2 rounded-lg"
                    (onClick)="downloadSignedDocument(signatory.signedAttachment)" />
                }
            </div>
        </div>
    </ng-template>
</p-timeline>
}