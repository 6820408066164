import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";

import { catchError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../@core/services/authentication.service";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {

  signupForm!: FormGroup;

  defaultUrl: string = '/contract-analyser'
  returnUrl: string = this.defaultUrl;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toastService: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.defaultUrl;
  }

  ngOnInit() {
    this.signupForm = this.fb.group({
      email: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const credentials = this.signupForm.value;
      this.authenticationService.login(credentials).pipe(catchError((error) => {
        return error;
      })).subscribe((userLoggedIn) => {

        if (userLoggedIn) {
          this.router.navigate([this.returnUrl])
        }
        else {
          this.toastService.error('Login Failed', 'Error!');
          this.router.navigate(['/onboard'])
        }
      })

    }
  }

}