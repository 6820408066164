@if(currentStep === 1){
<div class="aspect-auto flex h-screen w-screen" #step1>
    <div class="left-section">
        <div class="flex  flex-col">
            <div class="flex flex-row items-start px-6 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }"></div>
                    </div>
                </div>
            </div>
            <div class="profile-setup">
                <div class="mb-5">
                    <h2 class="heading">Setup your profile</h2>
                    <p class="text-gray-400 mb-2">Let's setup your profile , tell us more about you</p>
                </div>
                <form [formGroup]="profileForm" (ngSubmit)="onSubmitProfile()">
                    <div class="flex flex-row gap-2">
                        <div class="profile-picture-container">
                            <div class="profile-image">
                                <img class="h-[5rem] w-[5rem] rounded-full" [src]="profileForm.value.profilePicture"
                                    alt="Profile">
                            </div>

                            <div class="upload-section">
                                <div class="title">Profile Picture</div>

                                <label for="file-upload" class="upload-btn">
                                    <input id="file-upload" type="file" (change)="onFileSelect($event)" hidden />
                                    <i class="pi pi-upload mr-2"></i> Upload Picture
                                </label>
                                <p class="description">You can setup your profile picture later in settings</p>
                            </div>
                        </div>
                    </div>

                    <div class="flex gap-4 mt-4">
                        <div class="form-field">
                            <label for="firstName">First Name</label>
                            <input id="firstName" formControlName="firstName"
                                class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                                placeholder="John" />
                        </div>

                        <div class="form-field">
                            <label for="lastName">Last Name</label>
                            <input id="lastName" formControlName="lastName"
                                class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                                placeholder="Smith" />
                        </div>

                    </div>

                    <div class="form-field">
                        <label for="lastName">New Password</label>
                        <p-password class="border-1" [toggleMask]="true" />
                    </div>

                    <div class="form-field">
                        <label for="lastName">Confirm New Password</label>
                        <p-password class="border-1 " [toggleMask]="true" />
                    </div>

                    <div class="button-group">
                        <button type="submit" class="submit-button w-[23rem] " (click)="nextStep()">Continue</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>
}

@if(currentStep === 2){
<div class="aspect-auto flex h-screen w-screen" #step2>
    <div class="left-section">
        <div class="flex flex-col ">
            <div class="flex flex-row items-start px-6 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }"></div>
                    </div>
                </div>
            </div>

            <div class="question-section left-section-content ">
                <h2 class="heading">Looking Good,</h2>


                <p class="role-description">What role are you working on? Choose one. <span class="required">*</span>
                </p>
                <div class="role-buttons">
                    <button type="button" *ngFor="let role of roles" (click)="selectRole(role)"
                        [class.selected]="role === selectedRole">
                        {{ role }}
                    </button>
                </div>

                <p class="role-description">What is your domain expertise? Choose one. <span class="required">*</span>
                </p>
                <div class="expertise-buttons">
                    <button type="button" *ngFor="let expertise of domainExpertise" (click)="selectExpertise(expertise)"
                        [class.selected]="expertise === selectedExpertise">
                        {{ expertise }}
                    </button>
                </div>

                <div class="button-group">
                    <button type="submit" class="submit-button" (click)="nextStep()">Continue</button>
                </div>
            </div>
        </div>

    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>
}

@if(currentStep === 3){
<div class="aspect-auto flex h-screen w-screen" #step3>
    <div class="left-section">
        <div class="flex flex-col">
            <div class="flex flex-row items-start px-6 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }"></div>
                    </div>
                </div>
            </div>
            <div class="profile-setup">
                <div class="mb-5">
                    <h2 class="heading">Organization Setup</h2>
                    <p class="text-gray-400">To start using Inncircles.ai you need to create organization</p>
                </div>
                <form [formGroup]="organizationForm" (ngSubmit)="onSubmitOrganization()">
                    <!-- Form Fields -->
                    <div class="form-field mt-6">
                        <label for="companyName">Company Name</label>
                        <input id="companyName" formControlName="companyName"
                            class="input-field w-full border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                            placeholder="Your Company" />
                    </div>

                    <div class="form-field mt-6">
                        <label for="workspaceurl" class="font-bold mb-2">Workspace URL</label>
                        <div class="flex">
                          <input
                            id="workspaceurl"
                            [value]="workspacePrefix + workspaceInputValue"
                            class="input-field w-full  border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none rounded-md p-2"
                            (input)="onUrlInput($event)"
                            placeholder="your-workspace"
                          />
                        </div>
                      </div>

                    <div class="flex gap-2">
                        <div class="form-field">
                            <label for="companySize">Company Size</label>
                            <p-dropdown  [options]="noOfEmployees" optionLabel="code"
                                placeholder="Select Company Size" styleClass="border h-12 rounded-lg"  />
                        </div>

                        <div class="form-field">
                            <label for="contact">Phone</label>
                            <arn-phone-input (phoneNumberChange)="onPhoneNumberChange($event)"
                                formControlName="contact"></arn-phone-input>
                        </div>
                    </div>

                    <div class="form-field">
                        <label for="address ">Address</label>
                        <textarea rows="4" cols="50" id="address" formControlName="address"
                            class="input-field p-2 border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                            placeholder="Address"></textarea>
                    </div>

                    <div class="form-field">
                        <label for="companyEmail">Email</label>
                        <input type="email" formControlName="companyEmail"
                            class="input-field border-gray-300 focus:border-orange-500 focus:ring-orange-500 focus:outline-none"
                            placeholder="Email" />
                    </div>

                    <div class="button-group">
                        <button type="submit" class="submit-button" (click)="nextStep()">Continue</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>
}

@if(currentStep === 4){

<div class="aspect-auto flex h-screen w-screen" #step5>
    <div class="left-section">
        <div class="flex flex-col">
            <div class="flex flex-row items-start px-6 py-6 text-sm gap-3 ">
                <img src="/images/arena_ai_logo.png" alt="logo" class="w-10 h-10">
                <div class="flex flex-col gap-2">
                    <span class="text-sm font-medium">{{currentStep}} of {{totalSteps}} steps</span>
                    <div class="relative w-full h-2 bg-blue-100 rounded-full">
                        <div class="absolute top-0 h-2 bg-orange-500 rounded-full" [ngStyle]="{ width: progress }"></div>
                    </div>
                </div>
            </div>
            <div class="left-section-content">
                <div class="flex flex-col mb-5">
                    <h2 class="heading">Invite your team members</h2>
                    <p class="text-gray-400 text-center"> Invite People to your workspace </p>
                </div>

                <div class="members-list ">
                    <div class="flex flex-row justify-between mb-5">
                        <p>Email</p>
                        <p class="mr-[9.7rem]">Role</p>
                    </div>
                    @for (item of members; track $index) {
                    <div class="member-item gap-6">
                        <div class="form-field">
                            <input type="email"
                                class="border-gray-300  focus:border-orange-500 rounded-lg focus:ring-orange-500 focus:outline-none"
                                id="firstName" placeholder="somename@yourcompany.com" style="width: 18rem;" />
                        </div>
                        <p-dropdown [options]="userRoles" [(ngModel)]="selectedNoOfEmployees" optionLabel="code"
                            [style]="{'width':'200px', 'border':'1px solid lightgrey'}" placeholder="Select a Role" />
                    </div>
                    }
                    <div class="add-member-btn">
                        <button (click)="addMember()" class="mb-5">+ Add Another</button>
                    </div>

                </div>
                <div class="button-group">
                    <button type="submit" class="submit-button " (click)="nextStep()" style="width: 17rem; margin-left:11rem">Send Over
                        Mail</button>
                    <p class="text-center text-gray-500 cursor-pointer ml-12" (click)="onCancel()">I'll do this later</p>

                </div>
            </div>
        </div>
    </div>
    <div class="right-section">
        <img class="login-image" src="/images/login-page.png" alt="login-iamge">
    </div>
</div>
}