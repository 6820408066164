<p-button styleClass="border-2 py-1 px-2 cursor-pointer" icon="pi pi-share-alt" [rounded]="true" label="Share"
    (onClick)="getSharedUsers() ;op.toggle($event);" />

<p-overlayPanel [style]="{'border':'1px solid #EBEBED'}" #op>
    <div class="flex flex-col gap-3  w-[450px]">
        <span class="font-bold block mb-2 ">Share this document</span>
        <div class="flex flex-col gap-2 p-2 w-full">
            <label class="font-bold ">Invite Users</label>
            <p-multiSelect [options]="shareUsers" display="chip"
                [style]="{'width':'100%','padding':'6px','border':'2px solid #E7EAEE'}" [showClear]="true"
                placeholder="Search a User" optionLabel="label" (onChange)="shareToUser($event)">
                >
                <ng-template let-item pTemplate="item">
                    <div class="flex flex-row justify-start items-center p-2">
                        <div class="flex flex-row justify-between items-center gap-2">
                            <img [src]="item | userAvatar" alt="User Avatar" class="w-8 h-8 rounded-full" />
                            <div class="flex flex-col">
                                <span>{{item | fullName}}</span>
                                <span>{{item.email}}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
        </div>

        <div class="max-h-[13rem]  overflow-y-auto">
            @for (item of selectedShareUser; track $index) {
            <div class="flex  flex-row justify-between items-center bg-white rounded-lg p-3">
                <div class="flex flex-row justify-between items-center gap-2">
                    <img [src]="item.user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
                    <span>{{item.user | fullName }}</span>
                </div>

                <div class="flex gap-2  items-center">
                    <p-dropdown class="border  rounded-lg" [options]="docsShareOptions" placeholder="Select Role"
                        appendTo="body" [(ngModel)]="item.role" (onChange)="changeDocRole($event,item.user._id, true)">
                    </p-dropdown>
                    <p-button text="true" class="text-red-600" icon="pi pi-trash" severity="danger"
                        (onClick)="deleteDocRole(item.user._id)"></p-button>
                </div>
            </div>
            }
        </div>
        <div class="flex flex-col gap-2  p-3 rounded-lg bg-orange-100">
            <span class="font-bold">People who have access ({{sharedUsers.length}})</span>

            <div class="max-h-[14rem] overflow-y-auto ">
                @if(sharedUsers && sharedUsers.length>0){
                @for (item of sharedUsers; track $index) {

                <div class="flex flex-row gap-6 m-2 justify-between items-center bg-white rounded-lg p-3">
                    <div class="flex flex-row justify-between items-center gap-2">
                        <img [src]="item.user | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
                        <span>{{item.user | fullName }}</span>
                    </div>

                    <div class="flex gap-2 items-center">
                        <p-dropdown class="border rounded-lg " [options]="docsShareOptions" [(ngModel)]="item.role"
                            (onChange)="changeDocRole($event,item.user._id,false)">
                        </p-dropdown>
                        <p-button text="true" class="text-red-600" icon="pi pi-trash" severity="danger"
                            (onClick)="deleteDocRole(item.user._id)"></p-button>
                    </div>
                </div>
                }
                }
                @else {
                <span>No Users found </span>
                }
            </div>
        </div>
        <div class="flex gap-4 justify-end">
            <p-button styleClass=" px-3 py-2 rounded-xl" (onClick)="op.toggle($event)" label="Cancel" [text]="true"
                [plain]="true" />
            <p-button styleClass="bg-arena-orange px-3 py-2 rounded-xl text-white" label="Share"
                (onClick)="sendInvite()" [disabled]="!selectedShareUser.length" [rounded]="true" />
        </div>
    </div>
</p-overlayPanel>