import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUser } from '../../../@core/models/model';
import { TimelineModule } from 'primeng/timeline';
import { BadgeComponent } from "../badge/badge.component";
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { CardModule } from 'primeng/card';
import { CONTRACT_APPROVAL_STATUS } from '../user-chips/user-chips.component';
import { DateFormatterPipe } from '../../pipes/dateFormater.pipe';
import { ButtonModule } from 'primeng/button';
import { ContractService } from '../../../contract-management/services/contracts.service';
import { IContractApprovalConf } from '../../../contract-management/models/contract-management.model';
import { EmailAvatarPipe } from '../../pipes/email-avatar.pipe';
import { CONTRACT_STATUS } from '../badge/badge.enums';
import { ToastService } from '../../../@core/services/toast.service';
import { ApprovalConfService } from '../../../contract-management/services/approval-conf.service';


export enum FLOW_TYPE {
  APPROVAL = 'APPROVAL',
  SIGNATORY = 'SIGNATORY'
}
@Component({
  selector: 'app-approval-workflow',
  standalone: true,
  imports: [TimelineModule, BadgeComponent, UserAvatarPipe, EmailAvatarPipe, CardModule, DateFormatterPipe, ButtonModule],
  templateUrl: './approval-workflow.component.html',
  styleUrl: './approval-workflow.component.scss'
})

export class ApprovalWorkflowComponent {
  @Input() approvalConf !: IContractApprovalConf
  @Input() canEdit: boolean = false
  @Output() deleteApproverEvent = new EventEmitter<string>()
  @Output() deleteSignatoryEvent = new EventEmitter<string>()
  @Output() downloadSignatoryEvent = new EventEmitter<string>()
  @Input() approvers !: { user: IUser, status: string }[]
  @Input() signatories !: { email: string, name: string, status: string, signedAttachment?: string }[]
  @Input() flowType !: FLOW_TYPE
  @Input() contractStatus !: CONTRACT_STATUS

  CONTRACT_STATUS = CONTRACT_STATUS
  FLOW_TYPE = FLOW_TYPE
  APPROVAL_STATUS !: CONTRACT_APPROVAL_STATUS

  constructor(
    private approvalService: ApprovalConfService,
    private toast: ToastService
  ) { }

  deleteApprover(approver: string) {
    this.approvalService.deleteApprover(this.approvalConf.contract as string, approver).subscribe((res) => {
      this.deleteApproverEvent.emit(approver)
      this.toast.success('Approver Deleted Successfully');
    })
  }

  deleteSignatory(email: string) {
    this.deleteSignatoryEvent.emit(email)
  }

  async downloadSignedDocument(attachmentId: string) {
    this.downloadSignatoryEvent.emit(attachmentId)
  }

}

