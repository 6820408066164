import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../../services/contracts.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ContractInsightsService } from '../../services/contract-insights.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { IContractInsights, IContractRole } from '../../models/contract-management.model';
import { DateFormatterPipe } from "../../../@shared/pipes/dateFormater.pipe";


@Component({
  selector: 'app-contract-insights',
  standalone: true,
  imports: [AutoCompleteModule, DropdownModule, AccordionModule, BadgeModule, ProgressSpinnerModule, DateFormatterPipe],
  providers: [],
  templateUrl: './contract-insights.component.html',
  styleUrl: './contract-insights.component.scss'
})
export class ContractInsightsComponent implements OnInit {
  @Input() contractId: string = "";
  contract: any;
  locationSuggestions: any[] = [];
  clientSuggestions: any[] = [];
  contractTypes: any[] | undefined;
  scopeOfWorkSuggestions: any[] = [];
  contractInsights: IContractInsights | undefined;
  summaryLoading: boolean = true;

  constructor(private contractService: ContractService, private contractInsightsService: ContractInsightsService,) {
    this.contractService.fetchContract(this.contractId).subscribe((contract: any) => {
      this.contract = contract;
    });
  }
  ngOnInit(): void {
    this.generateSummary(false)
    this.getContractClauses(false)
  }

  searchLocation($event: AutoCompleteCompleteEvent) {
    throw new Error('Method not implemented.');
  }
  searchClient($event: AutoCompleteCompleteEvent) {
    throw new Error('Method not implemented.');
  }
  searchScopeOfWork($event: AutoCompleteCompleteEvent) {
    throw new Error('Method not implemented.');
  }

  getContractClauses(generateClauses: boolean) {
    if (this.contractId.length > 0) {
      this.contractInsightsService.generateClauses(this.contractId, generateClauses).subscribe(res => {
        console.log(res)
      })
    } else {
      console.error("No such contract")
    }
  }

  generateSummary(generateInsights: boolean) {
    if (this.contractId.length > 0) {
      this.summaryLoading = true;
      this.contractInsightsService.generateSummary(this.contractId, generateInsights).subscribe({
        next: (res: any) => {
          if (res.data) {
            this.contractInsights = res.data;
          }
          this.summaryLoading = false;
        },
        error: (error) => {
          this.summaryLoading = false;
        }
      });
    }
  }

}
