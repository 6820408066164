import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PhoneInputComponent } from "../../../../@shared/components/phone-input/phone-input.component";
import { DropdownModule } from 'primeng/dropdown';
import { AuthenticationService } from '../../../../@core/services/authentication.service';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';



@Component({
  selector: 'app-onboard-org',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, CheckboxModule, RadioButtonModule, CommonModule, FormsModule, PhoneInputComponent, DropdownModule, PasswordModule],
  templateUrl: './onboard-org.component.html',
  styleUrl: './onboard-org.component.scss'
})
export class OnboardOrgComponent implements OnInit {
  profileForm!: FormGroup;
  organizationForm!: FormGroup;
  teamForm!: FormGroup;
  currentStep = 3;
  totalSteps = 4;
  role: string = ''
  profileImage: string = '';

  sendOverEmail = false
  currentUser: any
  value!: string;
  workspacePrefix = 'inncircles.ai/';
  workspaceInputValue = ''; // This will hold the user input

  constructor(private fb: FormBuilder, private authService: AuthenticationService) {
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
    });
  }

  ngOnInit(): void {
    // Initialize form groups
    this.initializeForms();
  }

  roles = ['Individual contributor', 'Senior Leader', 'Manager', 'Executive', 'Freelancer', 'Student'];
  domainExpertise = ['Engineering', 'Product', 'Marketing', 'Sales', 'Operations', 'Legal', 'Finance', 'Human Resources', 'Project', 'Other'];
  members = [
    { email: '', role: '' },
    { email: '', role: '' }
  ]
  selectedRole: string | null = null;
  selectedExpertise: string | null = null;
  noOfEmployees = [
    { number: '1-10', code: '1-10' },
    { number: '11-50', code: '11-50' },
    { number: '51-200', code: '51-200' },
    { number: '501-1000', code: '501-1000' },
    { number: '1000+', code: '1000+' },
  ];
  userRoles = [
    { role: 'Admin', code: 'Admin' },
    { role: 'Member', code: 'Member' },
  ];
  
  selectedNoOfEmployees: string = ''
  // Calculates the percentage based on the current step
  get progress(): string {
    return `${(this.currentStep / this.totalSteps) * 100}%`;
  }

  // Move to the next step
  nextStep() {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    }
  }

  // Move to the previous step
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

 onUrlInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.startsWith(this.workspacePrefix)) {
      this.workspaceInputValue = inputValue.substring(this.workspacePrefix.length);
    } else {
      event.target.value = this.workspacePrefix + this.workspaceInputValue;
    }
  }

  addMember() {
    this.members.push({ email: '', role: '' })
  }
  removeMember(member: any) {
    this.members.pop()
  }

  selectRole(role: string): void {
    this.selectedRole = role;
  }

  selectExpertise(expertise: string): void {
    this.selectedExpertise = expertise;
  }

  initializeForms(): void {
    // Step 2: Profile Setup Form Group
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      profilePicture: ['https://avatar.iran.liara.run/public/19']
    });

    // Step 3: Organization Setup Form Group
    this.organizationForm = this.fb.group({
      companyName: ['', Validators.required],
      workspaceurl: ['', Validators.required],
      companySize: ['', Validators.required],
      contact: ['', Validators.required],
      address: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]]
    });

    // Step 4: Invite Team Members Form Group
    this.teamForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      preferredName: [''],  // Optional field
      email: ['', [Validators.required, Validators.email]],
      contact: ['', Validators.required],
      sendOverEmail: [false]  // Checkbox field
    });
  }

  onSubmitProfile(): void {
    if (this.profileForm.valid) {
      console.log('Profile Form Submitted', this.profileForm.value);
      this.nextStep();  // Move to the next step
    } else {
    }
  }

  onSubmitOrganization(): void {
    if (this.organizationForm.valid) {
      console.log('Organization Form Submitted', this.organizationForm.value);
      this.nextStep();  // Move to the next step
    } else {
      console.error('Organization Form is invalid');
    }
  }

  onSubmitTeam(): void {
    if (this.teamForm.valid) {
      console.log('Team Form Submitted', this.teamForm.value);
      this.nextStep();  // Proceed to the next action or step
    } else {
      console.error('Team Form is invalid');
    }
  }

  onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.profileForm.value.profilePicture = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  onSubmit(): void {
    if (this.profileForm.valid) {
      const formData = new FormData();
      formData.append('firstName', this.profileForm.get('firstName')?.value);
      formData.append('lastName', this.profileForm.get('lastName')?.value);
      formData.append('email', this.profileForm.get('email')?.value);
      const file = this.profileForm.get('profilePicture')?.value;
      if (file) {
        formData.append('profilePicture', file);
      }
      console.log(formData);
    }
  }

  removePicture(): void {
    this.profileImage = '';

  }


  
  onCancel(): void {
    console.log('Profile setup cancelled');
  }

  onPhoneNumberChange(phoneNumber: { countryCode: string, phoneNumber: string }) { }
}
