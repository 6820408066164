<div class="inn-flex-container flex flex-col justify-between">
    <div class="chat-section inn-flex-scroll " #scrollBottom>
        @if(showSuggestions){
        <div class="prompt-container">
            <!-- Bot Icon -->
            <div class="bot-icon">
                <img src="icons/ai_chat.svg" alt="Bot Icon" />
            </div>

            <!-- Title -->
            <p class="title"> Get started with these prompts</p>

            <!-- Prompt Buttons -->
            <div class="buttons-container">
                <div class="prompt-buttons">
                    <button class="prompt-button">Contract Types</button>
                    <button class="prompt-button">Managing Change Orders</button>
                </div>
                <div class="prompt-buttons" style="margin-left: 10px;">
                    <button class="prompt-button">Dispute Resolution</button>
                    <button class="prompt-button">Dispute Clauses</button>
                </div>
                <div class="prompt-buttons" style="margin-left: 40px;">
                    <button class="prompt-button">Service Agreement Clauses</button>
                </div>
            </div>

            <!-- SVGS -->
            <div class="vector-left">
                <img src="/images/yellow_vector.svg" alt="" />
            </div>
            <div class="vector-right">
                <img src="/images/blue_vector.svg" alt="" />
            </div>
        </div>
        }
        @if(!showSuggestions){
        <div class="mt-4">
            @for (conversation of conversations; track $index) {
            <app-ai-chat-bubble [Chat]="conversation"></app-ai-chat-bubble>
            }
        </div>
        }

    </div>
    <div class="justify-end">
        <div class="flex flex-col fixed-actions no-scroll gap-2">
            <div class="flex justify-between items-center prompt border-t-gray-200">
                <span class="svg-icon ml-2 stars" [innerHTML]="icons.AI_CHAT_STARS | sanitizeHtml"></span>
                <input type="text" placeholder="Type Something ..." [maxlength]="maxPromptLength" [(ngModel)]="prompt"
                    [disabled]="disabled" (input)="checkPromptLength()" (keydown.enter)="sendPrompt()"
                    class="w-full promptInput">
                @if (!loading) {
                <span [ngClass]="{'disabled-svg': disabled || promptLength<1 , 'send' : !disabled || promptLength>1}"
                    class="svg-icon mr-2" [innerHTML]="icons.CHAT_BOT_SEND | sanitizeHtml"
                    (click)="sendPrompt()"></span>
                }
                @if (loading) {
                <span class="loader mr-2"></span>
                }
            </div>
            <div class="flex justify-between gap-1 ml-3 mr-3 info">
                <div class="w-full text-sm">
                    This is an experimental generative AI Chatbot
                </div>
                <div>
                    @if (promptLength >= maxPromptLength) {
                    <div class="text-sm text-red-500">
                        {{promptLength}}/{{maxPromptLength}} (Maximum reached)
                    </div>
                    }
                    @if (promptLength < maxPromptLength) { <div>
                        {{promptLength}}/{{maxPromptLength}}
                </div>
                }

            </div>
        </div>
    </div>
</div>