import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, interval, switchMap } from 'rxjs';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AiChatService extends BaseService {
    private url = environment.apiUrl + '/contract-management/chat'
    constructor(
        protected override http: HttpClient,
        private _zone: NgZone
    ) {
        super(http);
    }

    fetchStreamedResponse(prompt: string, socketId: string) {
        return new Observable(observer => {
            let token = localStorage.getItem('token');
            const eventSource = new EventSource(`${this.url}/conversation?prompt=${encodeURIComponent(prompt)}&token=${token}&socketId=${socketId}`);
            eventSource.onmessage = event => {
                this._zone.run(_ => {
                    observer.next(event)
                    let data = JSON.parse(event.data)
                    if (data.status.includes('[DONE]')) {
                        eventSource.close();
                    }
                    if (data.status.includes('[ERR]')) {
                        //Need to handle error
                        eventSource.close();
                        observer.error(data);
                    }
                })
            }

            eventSource.onerror = error => {
                this._zone.run(_ => observer.error(error))
            }
        })
    }
    getResponse(prompt: string) {
        return this.get<string>(this.url, { prompt })
    }
    fecthSuggestions() {
        return this.get<string[]>(this.url + '/suggestions', {})
    }
    getResponseFromSuggestion(suggestion: string) {
        return this.get<string>(this.url + '/suggestion', { suggestion })
    }
    getHistory(userId: string) {
        return this.get<string[]>(this.url + '/history', { userId })

    }
    getAllConversations(userId: string, conversationId: string) {
        return this.get<string[]>(this.url + '/conversations', { userId, conversationId })
    }
}