@if(comments.length > 0){
<div class="comments-container">
    <div *ngFor="let comment of comments" class="comment-card" [ngClass]="comment.type" [ngStyle]="{ 
            'background-color': comment.user.colorCode + '55', 
            'border-left': '6px solid ' + comment.user.colorCode 
          }">
        <div class="comment-header">
            <img [src]="comment.user | userAvatar" alt="User Image" class="user-avatar">
            <div class="comment-details">
                <p><strong>{{comment.user.firstName}} {{comment.user.lastName}}</strong></p>
                <p>on {{ comment.createdAt | date }} at {{comment.createdAt | date:'shortTime'}}</p>
                <p class="comment-content">{{comment.content}}</p>
            </div>
        </div>
        <div *ngFor="let comm of comment.replies" class="reply">
            <div class="comment-header">
                <img [src]="comm.user | userAvatar" alt="User Image" class="user-avatar">
                <div class="comment-details">
                    <p><strong>{{comm.user.firstName}} {{comm.user.lastName}}</strong></p>
                    <p>on {{ comm.createdAt | date }} at {{comm.createdAt | date:'shortTime'}}</p>
                    <p class="comment-content">{{comm.content}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
}
@else {
<div class="inn-flex-container" style="margin-top: 52%;">
    <!-- Comments Image -->
    <div class="flex justify-center items-center">
        <img src="images/comments-initial-screen.svg" alt="Bot Icon" style="width: 148px; height: 164px;" />
    </div>

    <!-- Title -->
    <p class="title"> No Comments Yet</p>
    <p style="text-align: center; margin: 15px 15px 0 15px;">Comments will be shown here once people start collaborating
    </p>

    <!-- SVGS -->
    <div class="vector-left">
        <img src="/images/yellow_vector.svg" alt="" />
    </div>
    <div class="vector-right">
        <img src="/images/blue_vector.svg" alt="" />
    </div>
</div>
}