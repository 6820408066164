import { Component, Input, OnInit } from '@angular/core';
import { UserAvatarPipe } from '../../../../pipes/user-avatar-pipe';
import { AvatarModule } from 'primeng/avatar';
import { IUser } from '../../../../../@core/models/model';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { FormsModule } from '@angular/forms';
import { TimeSince } from '../../../../pipes/time-since.pipe'
import { CommentService } from '../../../../../services/comment.service';

@Component({
  selector: 'app-reply',
  standalone: true,
  imports: [UserAvatarPipe, AvatarModule, FormsModule, TimeSince],
  templateUrl: './reply.component.html',
  styleUrl: './reply.component.scss'
})

export class ReplyComponent implements OnInit {
  @Input() commentId!: string;
  currentUser: IUser | null | undefined;
  public comments: any;
  value!: string;

  constructor(
    private commentService: CommentService,
    private authService: AuthenticationService,
  ) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
  }

  ngOnInit() {
    this.callAPI()
  }

  callAPI() {
    this.commentService.getCommentById(this.commentId).subscribe(res => {
      this.comments = res.data;
    });
  }

  sendMessage(reply: string) {
    this.commentService.replyToAComment(this.currentUser?._id ?? '', this.commentId, reply.trim()).subscribe((res) => {
    })
    this.callAPI()
    this.value = ''
  }
}


