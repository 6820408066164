import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { BaseService } from "../../@core/services/base.service";
import { IContract, IContractApprovalConf } from "../models/contract-management.model";

@Injectable({ providedIn: 'root' })
export class ApprovalConfService extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/approval-conf';



    addApprovers(contractId: string, approvers: string[]) {
        return this.post<IContract>(this.apiUrl + '/add-approvers/' + contractId, { approvers })
    }


    approveContract(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/approve-contract/' + contractId)
    }

    rejectContract(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/reject-contract/' + contractId)
    }

    resetContract(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/reset-contract/' + contractId)
    }

    fetchContractApprovers(contractId: string) {
        return this.get<IContractApprovalConf>(this.apiUrl + '/fetch-contract-approvers/' + contractId)
    }

    sendForApproval(contractId: string) {
        return this.post<IContract>(this.apiUrl + '/send-for-approval/' + contractId)
    }

    deleteApprover(contractId: string, approverId: string) {
        return this.delete<string>(this.apiUrl + '/remove-approver/' + contractId + '/' + approverId)
    }

}