import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  OnInit
} from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { ToastService } from '../../../@core/services/toast.service';

@Component({
  selector: 'app-file-drop',
  standalone: true,
  imports: [CommonModule, ToastModule],
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  @Input() title: string = '';
  @Input() showDesc: boolean = true;
  @Input() fileTypes: string[] = [];
  @Input() displayText: boolean = true;

  @Output() uploadData = new EventEmitter<File>();

  dragAreaClass: string = '';
  fileName: string = '';
  isFileAdded: boolean = false;
  message: string = '';

  constructor(
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.dragAreaClass = 'dragarea';
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }
  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      this.saveFiles(files);
      input.value = '';
    }
  }
  saveFiles(files: FileList) {
    const file = files[0];
    if (files.length > 1) {
      this.toast.error('Only one file at a time is allowed');
    } else {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
      if (!this.fileTypes.includes('.' + fileExtension)) {
        this.toast.error(`Only ${this.fileTypes} are allowed`);
      } else {
        this.message = '';
        this.isFileAdded = true;
        this.fileName = file.name;
        this.uploadData.emit(file);
        this.toast.success('File uploaded successfully');
      }
    }
  }
}
