<div class="w-full flex flex-col">
    <div class="flex-grow accordion-container">
        <p-accordion [activeIndex]="1" styleClass="border-accordion gap-accordion">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="flex items-center gap-2 w-full">
                        <i class="pi pi-file-edit mr-2"></i>
                        <span class="font-bold white-space-nowrap">
                            Summary
                        </span>
                        <span
                            class="ml-2 p-2 flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium theme-text ring-1 ring-inset ring-blue-700/10">
                            <img src="icons/star.svg" class="mr-1.5 h-3 w-3 text-blue-400" alt="Star icon">
                            AI Generated
                        </span>
                    </div>

                </ng-template>
                @if (summaryLoading) {
                <div class="flex justify-center items-center h-full">
                    <p-progressSpinner styleClass="my-4" [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
                </div>
                } @else{

                <div class="flex flex-col justify-center items-center text-sm p-2">
                    @if (contractInsights && !contractInsights.isValidContract) {
                    <div
                        class="flex items-center justify-between p-1 w-full my-2 bg-red-100 border border-red-300 rounded">
                        <span class="text-red-700 font-bold text-sm">This document may not conform to standard contract
                            formats.</span>
                        <i class="pi pi-times text-red-700 cursor-pointer"></i>
                    </div>
                    }
                    <div class=" flex justify-between items-center text-xs font-bold my-2 w-full ">
                        <div>
                            Last Updated : {{$any(contractInsights?.updatedAt) | dateFormatter :'DD/MM/yyyy , HH:MM a'}}
                        </div>
                        <div (click)="generateSummary(true)"
                            class="cursor-pointer flex items-baseline gap-2 text-arena-orange-900  hover:text-arena-orange-500 transition-colors duration-300 rounded-md">
                            <i class="pi pi-refresh text-sm"></i>
                            <span class="font-semibold"> Refresh</span>
                        </div>
                    </div>
                    <div class="text-justify"> {{contractInsights?.summary}} </div>
                    <div class="flex flex-col  gap-2">
                        @for (section of contractInsights?.sections; track section.title) {
                        @if(section.content) {
                        <div class="text-gray-700 text-left font-bold text-md "> {{section.title}}</div>
                        <div class="text-gray-700 text-justify"> {{section.content}}</div>
                        }
                        }
                    </div>
                </div>
                }
            </p-accordionTab>

            <p-accordionTab>
                <ng-template pTemplate="header">
                    <span class="flex items-center gap-2 w-full">
                        <i class="pi pi-bars mr-2"></i>
                        <span class="font-bold white-space-nowrap">
                            Key Highlights
                        </span>
                    </span>
                </ng-template>
                <form class="p-3">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="mb-3 col-span-1 ">
                            <label for="location" class="block text-sm font-medium text-gray-700">Location</label>
                            <p-autoComplete id="location" [suggestions]="locationSuggestions"
                                (completeMethod)="searchLocation($event)" class="w-full"></p-autoComplete>
                        </div>
                        <div class="mb-3 col-span-1">
                            <label for="client" class="block text-sm font-medium text-gray-700">Client</label>
                            <p-autoComplete id="client" [suggestions]="clientSuggestions"
                                (completeMethod)="searchClient($event)" class="w-full"></p-autoComplete>
                        </div>
                        <div class="mb-3 col-span-2">
                            <label for="contractType" class="block text-sm font-medium text-gray-700">Type of
                                Contract</label>
                            <p-dropdown styleClass="w-full" id="contractType" [options]="contractTypes"
                                optionLabel="name" [filter]="true" filterBy="name" class="w-full"></p-dropdown>
                        </div>
                        <div class="mb-3 col-span-2">
                            <label for="scopeOfWork" class="block text-sm font-medium text-gray-700">Scope of
                                Work</label>
                            <p-dropdown styleClass="w-full" id="scopeOfWork" [options]="scopeOfWorkSuggestions"
                                optionLabel="name" [filter]="true" filterBy="name" class="w-full"></p-dropdown>
                            <!-- <p-autoComplete id="scopeOfWork" [suggestions]="scopeOfWorkSuggestions"
                                (completeMethod)="searchScopeOfWork($event)" [multiple]="true" [dropdown]="true"
                                class="w-full"></p-autoComplete> -->
                        </div>
                    </div>
                </form>
            </p-accordionTab>

            <p-accordionTab>
                <ng-template pTemplate="header">
                    <span class="flex items-center gap-2 w-full">
                        <i class="pi pi-file-edit mr-2"></i>
                        <span class="font-bold white-space-nowrap">
                            Checkpoints
                        </span>
                        <p-badge value="3" class="ml-auto mr-2" />
                    </span>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>