import { Component } from '@angular/core';
import { ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatMessage, CONTENT_TYPE, SENDER_TYPE } from './chat.types';
import { AiChatService } from '../../services/aichat.service';
import { SVG_ICONS } from '../../constants/constant';
import { AiChatBubbleComponent } from './components/ai-chat-bubble/ai-chat-bubble.component';
import { SanitizeHtmlPipe } from '../../../@shared/pipes/sanitizeHtml.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, FormsModule, AiChatBubbleComponent, SanitizeHtmlPipe],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  public icons = SVG_ICONS;
  public maxPromptLength: number = 300
  public promptinput: string = ''
  public promptLength: number = 0
  public disabled: boolean = false

  public inputPrompt: string = ''
  public prompt: string = ''
  public showSuggestions: boolean = true;
  public loading: boolean = false;
  public conversations: ChatMessage[] = [];
  public promptSuggestions: string[] = [
    'What is equipment management?',
    'How to create a project?',
    'How to setup global data for safety?',
    'Bid Management',
    'Cost Tracking',
    'Request Demo',
    'Arena Walkthrough'
  ];

  @ViewChild('scrollBottom', { static: true })
  private scrollBottom!: ElementRef;
  constructor(
    private aiChatbotService: AiChatService,
    // private notificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<ChatComponent>,
  ) { }
  ngOnInit(): void {
    this.getSuggestions();
    // this.getStreamedReponseTest()
  }
  getStreamedReponseTest() {
    this.aiChatbotService.fetchStreamedResponse('test', 'socket').subscribe(data => console.log(data))
    this.scrollToBottom();
  }
  getSuggestions() {
    // this.aiChatbotService.fecthSuggestions().subscribe(res => {
    //   if(res && res.data){
    //     this.promptSuggestions = res.data;
    //   }
    // });
  }
  suggestionClick(suggestion: string) {
    this.showSuggestions = false;
    this.conversations.push({
      content: suggestion,
      contentType: CONTENT_TYPE.TEXT,
      timestamp: new Date().toISOString(),
      sender: SENDER_TYPE.USER,
      isLoading: false,
    });
    this.loading = true;
    this.conversations.push(
      {
        content: '',
        contentType: CONTENT_TYPE.TEXT,
        timestamp: new Date().toISOString(),
        sender: SENDER_TYPE.AI,
        isLoading: true,
      }
    );
    // const socketId = this.notificationService.getSocketId()
    this.aiChatbotService.fetchStreamedResponse(suggestion, '1234').subscribe((res: any) => {
      if (res && res.data) {
        let data = JSON.parse(res.data)
        if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.content.message.includes('[JIRA]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].contentType = CONTENT_TYPE.TICKET;
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
        else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
      }
    })
  }
  getResponse(value: string) {
    this.showSuggestions = false;
    this.conversations.push({ content: value, contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.USER, isLoading: false });
    this.loading = true;
    this.conversations.push({ content: '', contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.AI, isLoading: true });
    // const socketId = this.notificationService.getSocketId()
    this.aiChatbotService.fetchStreamedResponse(value, '1234').subscribe((res: any) => {
      if (res && res.data) {
        let data = JSON.parse(res.data)
        if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.content.message.includes('[JIRA]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].contentType = CONTENT_TYPE.TICKET;
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
        else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
      }
    })
    // this.scrollToBottom()
    // this.conversations[this.conversations.length - 1].content = "Test Messag"
    // this.conversations[this.conversations.length - 1].isLoading = false;
    // this.loading = false;
    // this.disabled = false;
  }
  checkPromptLength() {
    this.promptLength = this.prompt.length;
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
      }, 100)
    } catch (err) {
    }
  }
  sendPrompt() {
    if (this.promptLength > 0) {
      this.loading = true;
      this.promptinput = this.prompt;
      this.prompt = '';
      this.checkPromptLength();
      this.disabled = true;
      this.getResponse(this.promptinput);
      this.scrollToBottom();
    }
  }

}
