import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";
import { BaseService } from "../../@core/services/base.service";
import { IContract } from "../models/contract-management.model";
import { IUser } from "../../@core/models/model";


@Injectable({ providedIn: 'root' })
export class UserService  extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    apiUrl = environment.apiUrl + '/contract-management/user'
    usersUrl = environment.apiUrl + '/user'

    getUsers() {
        return this.get<IUser[]>(this.usersUrl + '/list')
    }

}
