import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CommentService } from '../../../services/comment.service';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';

@Component({
  selector: 'app-all-comments',
  standalone: true,
  imports: [CommonModule, UserAvatarPipe],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss'
})
export class CommentsComponent implements OnInit {
  constructor(private commentService: CommentService) {
  }
  comments: any[] = [];
  ngOnInit() {
    // Initial comments data
    this.commentService.getAllComments().subscribe((res) => {
      this.comments = res.data;
    })
  }
}
