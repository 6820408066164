import { Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { EditContractComponent } from './contract-management/components/edit-contract/edit-contract.component';

import { AuthGuard } from './@core/guards/auth.guard';
import { ContractListComponent } from './contract-management/components/contract-list/contract-list.component';
import { LogInComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { OnboardOrgComponent } from './auth/sign-up/components/onboard-org/onboard-org.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'contract-analyser',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LogInComponent
    },
    {
        path: 'signup',
        component: SignUpComponent
    },
    {
        path: 'onboard',
        component: OnboardOrgComponent
    },
    {
        path: 'contract-analyser',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./contract-management/contract-management.routes').then(mod => mod.ContractManagementLayoutRoutes)
    },
    {
        path: 'edit-contract',
        component: EditContractComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    },
];
