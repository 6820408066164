import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './@core/services/authentication.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'arena-ai-frontend';
  constructor(
    private primengConfig: PrimeNGConfig,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      modal: 1200,    // dialog, sidebar
      overlay: 1200,  // dropdown, overlaypanel
      menu: 1200,     // overlay menus
      tooltip: 1200   // tooltip
    };
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  receiveMessage(event: MessageEvent) {

    // Verify the origin of the data for security
    if (event.origin !== 'http://localhost:4200') return;

    if (event.data.type === 'ARENA_CONTRACT_ANALYSER') {
      const data = event.data.data;
      const user = this.authenticationService.setUserData(data);
      if (user) {
        this.router.navigate(['/contract-analyser'])
      } else {
        this.router.navigate(['/'])
      }
    }
  }

}
