<div class="phone-input-wrapper">
    <div class="phone-input-flex" [ngStyle]="{'border' : (formControl && formControl.dirty && formControl.invalid && !isFocused()) ?  '1px solid var(--errorColor)':'1px solid var(--gray300)'}">
        <span class="country-code" (click)="toggleDropDown()">
            +{{selectedPhoneNumber().countryCode}}
            <i [ngClass]="!isDropDownOpen() ? 'ph ph-caret-down': 'ph ph-caret-up'"></i>
        </span>
        <input type="text"  [value]="selectedPhoneNumber().phoneNumber" (focus)="isFocused.set(true)" (blur)="isFocused.set(false)" (input)="setPhoneNumber($event)" maxlength="15">
    </div>
    @if(isDropDownOpen()){
    <div class="country-dropdown">
        <input class="country-search" type="text" placeholder="Search country" [value]="searchText()" (input)="searchCountry($event)" >
        <ul class="dropdown-list">
            @if(searchDropDownOptions().length){
            @for(countryCode of searchDropDownOptions(); track countryCode;){
            <li class="country-code-item font-weight-500" (click)="setCountryCode(countryCode)">
                <span>{{countryCode.name}}</span>
                <span>({{countryCode.phoneCode}})</span>
            </li>
            }
            }
            @else {
            <li>No Country Found</li>
            }
        </ul>
    </div>
    }
</div>