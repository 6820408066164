import { Component, EventEmitter, Output } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { AuthenticationService } from '../../../../../@core/services/authentication.service';
import { IUser } from '../../../../../@core/models/model';
import { UserAvatarPipe } from '../../../../pipes/user-avatar-pipe';
import { CommentService } from '../../../../../services/comment.service';

@Component({
  selector: 'app-add-comment',
  standalone: true,
  imports: [AvatarModule, UserAvatarPipe],
  templateUrl: './add-comment.component.html',
  styleUrl: './add-comment.component.scss'
})
export class AddCommentComponent {
  currentUser!: IUser | null | undefined;
  @Output() id = new EventEmitter<string>();

  constructor(
    private authService: AuthenticationService,
    private commentService: CommentService
  ) {
    this.authService.currentUser.subscribe(res => this.currentUser = res)
  }

  onEnter(event: Event) {
    event.preventDefault();
    if (event.target instanceof HTMLInputElement) {
      const enteredComment = event.target.value;
      if (enteredComment) {
        this.commentService.addComment(this.currentUser?._id ?? '', enteredComment).subscribe(res => {
          this.id.emit(res.data);
        })
      }
    }
  }
}
