<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-col align-items-center p-5 surface-overlay border-round w-[30rem]">
            <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>

            <p class="mb-0">{{ message.message }}</p>

            <div class="flex justify-end items-end float-left gap-2 mt-4">

                <p-button label="Yes" (onClick)="cd.accept()"
                    styleClass="bg-white rounded-xl border border-green-500 text-green-500 hover:bg-green-100 hover:text-green-500 px-4 py-2 "
                    icon="pi pi-check"></p-button>

                <p-button label="No" (onClick)="cd.hide()"
                    styleClass="bg-white rounded-xl border border-red-500 text-red-500 hover:bg-red-100 hover:text-red-500 px-4 py-2 "
                    icon="pi pi-times"></p-button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

@if(contract){
<section class="aspect-auto h-screen flex flex-col">
    <div class="flex bg-white justify-between items-center p-2 pr-5 drop-shadow relative z-10">
        <div class="flex items-center" #left>
            <i class="pi pi-angle-left cursor-pointer m-2 text-primary" (click)="goBack()"></i>
            <div>
                <div class="flex items-center">
                    <span class="text-xl mx-3" contenteditable="true" (blur)="updateContractName($event)">
                        {{contract.name}} </span>
                    <i class="pi pi-cloud-download cursor-pointer m-2 text-primary"></i>
                    <app-badge [contract]="contract"></app-badge>
                    <p-menubar [model]="menuItems" />
                </div>
            </div>
        </div>

        <div #right class="flex gap-3 items-center">
            <app-badge [permission]="role"></app-badge>
            <i class="pi pi-refresh last-edit" [ngClass]="{'pi-spin': loading}" style="cursor: pointer;"
                (click)="getLastModified()">
            </i>
            <p class="last-edit">Last Edit on {{contract.updatedAt| date:'mediumDate'}} at {{lastModified |
                date:'shortTime'}}</p>
            @if ((connectedUsers$ | async)!.length) {
            <p-avatarGroup styleClass="rounded-full p-2 pb-0">
                @for (user of (connectedUsers$ | async)?.slice(0,4); track $index) {
                <p-avatar [image]="user | userAvatar" shape='circle'></p-avatar>
                }
                @if ((connectedUsers$ | async)!.length > 4) {
                <p-avatar [label]="'+' + (connectedUsers$ | async)!.length" shape="circle"
                    [style]="{ 'background-color': '#FCFCFD', color: '#1C1C1C' }" />
                }
            </p-avatarGroup>
            }
            @if(this.isOwner){
            <app-share-doc [contractId]="contractId" />
            }
            @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
            <!-- TODO - revert the disable -->
            <p-button [ngClass]="labelText === 'Publish' ? 'publish' : 'approval'" styleClass="border-2 py-1 px-2"
                [rounded]="true" [disabled]="actionButton" (onClick)="action()">
                <img [src]="getIconPath()" class="mr-1" alt="Icon" style="width: 20px; height: 20px;" />
                {{labelText}}
            </p-button>
            }
        </div>
    </div>

    <div class="flex-1 grid grid-cols-12">
        <div class="col-span-9 overflow-hidden">
            <app-contract-editor [contractId]="contract._id" class="h-full block overflow-hidden" [canView]="canView"
                [canEdit]="canEdit" [canComment]="canComment" [isOwner]="isOwner" [approvers]="approvalConf"
                [(needApproval)]="approver" (needApprovalChange)="approvalChange()" [currentApprover]="currentApprover"
                #contractEditor></app-contract-editor>
        </div>
        <div class="col-span-3 bg-white border-l-2 p-2 overflow-scroll comments">
            <p-tabView [(activeIndex)]="commentService.tabActiveIndex" class="inheri" styleClass="h-full  overflow-hidden">
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2  items-center">
                            <span class="pi pi-chart-line"></span>
                            <span class="text-lg font-semibold">Insights</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="h-full">
                            <app-contract-insights class="block h-full w-full"
                                [contractId]="contractId"></app-contract-insights>
                        </div>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-check-square"></span>
                            <span class="text-lg font-semibold">Approvers</span>
                        </div>
                    </ng-template>
                    <div class="flex flex-col gap-4 p-2 justify-evenly">
                        <div class="flex flex-row ">
                            <span class="text-lg font-semibold">Approvers</span>
                            <span class="text-sm text-gray-400 cursor-pointer ml-auto">can approve</span>
                        </div>

                        <div class=" overflow-auto max-h-[35rem] min-w-80 overflow-x-hidden no-scrollbar">
                            @if(approvalConf && approvalConf.approvers.length > 0){
                            <app-approval-workflow [approvalConf]="approvalConf" [canEdit]="!canEdit && !isOwner"
                                (deleteApproverEvent)="approvalChange()"
                                [flowType]="flowType.APPROVAL"></app-approval-workflow>
                            }
                            @else {
                            <div class="flex justify-center items-center">
                                <span class="text-gray-400">No approvers added</span>
                            </div>
                            }
                        </div>

                        <div class="flex   flex-row justify-center items-center">
                            <p-button label="Add Approver" icon="pi pi-plus"
                                [disabled]="(!canEdit && !isOwner )|| (contract.status == 'APPROVED')"
                                styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2" [severity]="'primary'"
                                [rounded]="true" (onClick)="approversMenu.toggle($event)"></p-button>
                        </div>

                        <p-overlayPanel #approversMenu styleClass="shadow-lg border border-gray-300 p-2 rounded-xl "
                            appendTo="body" [style]="{'width':'300px' ,'height' : '340px'}">
                            <ng-template pTemplate="start">
                                <div class="flex flex-col gap-2 ">
                                    <span class="font-bold text-sm">Add User to the approval level</span>

                                    <label class="font-semibold text-sm " for="name">Name</label>
                                    <p-iconField iconPosition="left">
                                        <p-inputIcon styleClass="pi pi-search" />
                                        <input type="text" pInputText
                                            class="w-full h-10 rounded-lg border p-1 pl-8 border-gray-300 outline-orange-400"
                                            (input)="searchUser($event)" placeholder="Search Approvers" />
                                    </p-iconField>
                                    <div class="flex flex-col gap-2 h-32 overflow-y-auto no-scrollbar overflow-hidden">
                                        @for (item of filterdUsers; track $index) {
                                        <div class="flex items-center space-x-2 text-black px-3 py-3 rounded-xl border cursor-pointer hover:bg-gray-100"
                                            (click)=" selectApprover(item)">
                                            <p-checkbox [(ngModel)]="item.selected" (onChange)="selectApprover(item)"
                                                name="group-{{item._id}}" binary="true"></p-checkbox>
                                            <img [src]="item | userAvatar" alt="User Avatar"
                                                class="w-6 h-6 rounded-full" />
                                            <span>{{item.fullName ? item.fullName : item.firstName}}</span>
                                        </div>
                                        }
                                    </div>

                                    <p-button styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"
                                        (onClick)="addApprovers(); approversMenu.hide()" label="Add Approvers"
                                        icon="pi pi-plus" [disabled]="!selectedApprovers.length"></p-button>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-check-square"></span>
                            <span class="text-lg font-semibold">Signatories</span>
                        </div>
                    </ng-template>
                    <div class="flex flex-col gap-4 pl-2 justify-evenly mt-4">
                        <div class="flex flex-row justify-between">
                            <div class="flex items-center">
                                <span class="text-lg font-semibold">Signatories</span>
                            </div>

                            <div class="flex">
                                @if(signatoryType == CONTRACT_SIGNATORY_TYPE.ADOBE_SIGN){
                                <div class="flex items-center mr-2" [pTooltip]="tooltipContentAdobe"
                                    tooltipPosition="top">
                                    <img src="/images/logos/adobe.png" height="24" width="24" alt="icon">
                                </div>
                                }@else if(signatoryType == CONTRACT_SIGNATORY_TYPE.DOCU_SIGN){
                                <div class="flex items-center mr-2" [pTooltip]="tooltipContentDocu"
                                    tooltipPosition="top">
                                    <img src="/images/logos/docusign.png" height="24" width="24" alt="icon">
                                </div>
                                }
                                @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
                                <p-button styleClass="border-2 py-1 px-2 rounded-xl" label="Reset" icon="pi pi-refresh"
                                    [disabled]="this.contract.status == CONTRACT_STATUS.READY_FOR_SIGNATURE"
                                    (onClick)="resetSignatoryFlow()" />
                                }
                            </div>
                        </div>
                        <div class="mt-4">
                            @if(signatoryConf && signatoryConf.signatories.length > 0){
                            <app-approval-workflow [signatories]="signatories" [flowType]="flowType.SIGNATORY"
                                (deleteSignatoryEvent)="signatoryChange($event)"
                                (downloadSignatoryEvent)="downloadSignedDocument($event)"
                                [contractStatus]="contract.status"></app-approval-workflow>
                            }
                            @else {
                            <div class="flex justify-center items-center">
                                <span class="text-gray-400">No Signatories added</span>
                            </div>
                            }
                        </div>

                        <div class="flex  flex-row justify-center items-center">
                            <p-button label="Add Signatories" icon="pi pi-plus"
                                styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2" [severity]="'primary'"
                                [rounded]="true" [disabled]="disableAddSignatories"
                                (onClick)="signatoriesMenu.toggle($event)"></p-button>
                        </div>
                        <p-overlayPanel #signatoriesMenu styleClass="shadow-lg border border-gray-300 p-2 rounded-xl "
                            appendTo="body" [style]="{'width':'300px' ,'height' : '300px'}">
                            <ng-template pTemplate="start">
                                <div class="flex flex-col gap-2 ">
                                    <span class="font-bold text-lg">Select Signatories</span>
                                    <input type="text" pInputText [(ngModel)]="signatoryEmail"
                                        (ngModelChange)="updateName()"
                                        class="w-full h-10 rounded-lg border p-1 border-gray-300" placeholder="Email" />
                                    <input type="text" pInputText [(ngModel)]="signatoryName"
                                        class="w-full h-10 rounded-lg border p-1 border-gray-300" placeholder="Name" />
                                    <p-button styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"
                                        (onClick)="addSignatory(); signatoriesMenu.hide()" label="Add Signatories"
                                        icon="pi pi-plus" [disabled]="!signatoryEmailValidation"></p-button>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </div>
                    <!-- <p-overlayPanel #approversMenu styleClass="shadow-lg border border-gray-300 p-2 rounded-xl "
                        appendTo="body" [style]="{'width':'300px' ,'height' : '300px'}">
                        <ng-template pTemplate="start">
                            <div class="flex flex-col gap-2 ">
                                <span class="font-bold text-lg">Select Approvers</span>
                                <input type="text" pInputText class="w-full h-10 rounded-lg border p-1 border-gray-300"
                                    placeholder="Search Approvers" />
                                <div class="flex flex-col  gap-2  h-32 overflow-y-auto no-scrollbar overflow-hidden">
                                    @for (item of users; track $index) {
                                    <div
                                        class="flex items-center space-x-2  text-black px-3 py-3 rounded-xl  border cursor-pointer  hover:bg-gray-100 ">
                                        <p-checkbox [(ngModel)]="item.selected" (onChange)="selectApprover(item)"
                                            name="group-{{item._id}}" binary="true"></p-checkbox>
                                        <img [src]="item | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
                                        <span>{{item.fullName ? item.fullName : item.firstName}}</span>
                                    </div>
                                    }
                                </div>
                                <p-button styleClass="bg-arena-orange p-3 rounded-xl w-full text-white"
                                    (onClick)="addApprovers(); approversMenu.hide()" label="Add Approvers"
                                    icon="pi pi-plus" [disabled]="!selectedApprovers.length"></p-button>
                            </div>
                        </ng-template>
                    </p-overlayPanel> -->
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-inbox"></span>
                            <span class="text-lg font-semibold">Chat</span>
                        </div>
                    </ng-template>
                    <div class="min-w-[300px] 2xl:min-w-[400px] chat-container border border-gray-200 rounded-lg">
                        <app-chat></app-chat>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <div class="flex flex-row gap-2 justify-between items-center">
                            <span class="pi pi-inbox"></span>
                            <span class="text-lg font-semibold">Comments</span>
                        </div>
                    </ng-template>
                    <div class="min-w-[300px] 2xl:min-w-[400px] comment-container border border-gray-200 rounded-lg">
                        <app-all-comments />
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</section>
}

<ng-template #tooltipContentAdobe>
    <div class="flex align-items-center">
        <span> Adobe sign </span>
    </div>
</ng-template>

<ng-template #tooltipContentDocu>
    <div class="flex align-items-center">
        <span> Docu sign </span>
    </div>
</ng-template>