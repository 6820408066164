<div class="comment-dialog">
    <div>
        <div class="flex gap-2 mt-2">
            <p-avatar [image]="comments.user | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
                severity="danger" />
            <p class="font-semibold text-sm"><b>{{comments.user.firstName}} {{comments.user.lastName}}</b></p>
            <div class="circle mt-2"></div>
            <p class=" time-field font-medium text-sm">{{ comments.createdAt | TimeSince }}</p>
        </div>
        <p class="pl-9 text-base ">{{comments.content}}</p>
    </div>

    <!-- Replies -->
    @for(comment of comments.replies; track comment._id){
    <div>
        <div class="flex gap-2 mt-2">
            <p-avatar [image]="comment.user | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
                severity="danger" />
            <p class="font-semibold text-sm username"><b>{{comment.user.firstName}} {{comment.user.lastName}}</b></p>
            <div class="circle mt-2"></div>
            <p class=" time-field font-medium text-sm">{{ comment.createdAt | TimeSince }}</p>
        </div>
        <p class="pl-9 text-base ">{{comment.content}}</p>
    </div>
    }

    <!-- Reply Box -->
    <div class="flex mt-8 gap-3">
        <div class="flex gap-2 items-center">
            <p-avatar [image]="currentUser | userAvatar" styleClass="header-avatar" shape="circle" size="normal"
                severity="danger" />
        </div>

        <div class=" relative flex items-center">
            <input type="text" class="p-2 pr-8 reply-input" [(ngModel)]="value" placeholder="Reply....."
                (keyup.enter)="sendMessage(value)">
            <button (click)="sendMessage(value)"
                class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-none p-0">
                <i class="pi pi-arrow-up text-lg"></i>
            </button>
        </div>
    </div>

</div>