<div draggable="true" class="content" [class]="dragAreaClass">
  <div class="text-center">
    @if(showDesc) {
    @if(!isFileAdded || !displayText){
    <div class="d-flex flex-column align-items-center justify-content-center text-center">
      <i class="pi pi-cloud-upload upload-image"></i>
      <h4 class="title">Drag your files here,</h4>
      <p class="drag-text" tabindex="0" (click)="file.click()" (keydown.enter)="file.click()"
        (keydown.space)="file.click()">
        or
        <span class="click-here-input">
          click here
        </span>
        to upload files from your device
      </p>
    </div>
    } @else{
    <h4 class="drag-text-uploaded">{{ fileName }} File is Uploaded</h4>
    }
    <input type="file" #file [accept]="fileTypes" [multiple]="false" (change)="onFileChange($event)"
      style="display: none" />
    }
  </div>
</div>