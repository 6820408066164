import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CONTRACT_SIGNATORY_TYPE, IContract, IContractApprovalConf, IContractSignatoryConf } from '../../models/contract-management.model';
import { ContractEditorComponent } from "../../../@shared/components/contract-editor/contract-editor.component";
import { TabMenuModule } from 'primeng/tabmenu';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CommentsComponent } from '../comments/comments.component';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { ContractService } from '../../services/contracts.service';
import { BehaviorSubject } from 'rxjs';
import { CONTRACT_USER_ROLE, IUser } from '../../../@core/models/model';
import { AsyncPipe, CommonModule } from '@angular/common';
import { UserAvatarPipe } from '../../../@shared/pipes/user-avatar-pipe';
import { TabViewModule } from 'primeng/tabview';
import { CONTRACT_APPROVAL_STATUS, UserChipsComponent } from "../../../@shared/components/user-chips/user-chips.component";
import { UserService } from '../../services/user.service';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, NgModel } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { AuthenticationService } from '../../../@core/services/authentication.service';
import { ContractInsightsComponent } from "../contract-insights/contract-insights.component";
import { CONTRACT_SIGNATORY_STATUS, CONTRACT_STATUS } from '../../../@shared/components/badge/badge.enums';
import { ApprovalWorkflowComponent, FLOW_TYPE } from "../../../@shared/components/approval-workflow/approval-workflow.component";
import { validateEmail } from '../../../@shared/components/contract-editor/plugins/utils';
import { FullNamePipe } from '../../../@shared/pipes/fullName.pipe';
import { ToastService } from '../../../@core/services/toast.service';
import { ShareDocComponent } from "./components/share-doc/share-doc.component";
import { MenubarModule } from 'primeng/menubar';
import { CreateContractComponent } from '../create-contract/create-contract.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ChatComponent } from "../chat/chat.component";
import { CommentService } from '../../../services/comment.service';
import { BadgeComponent } from "../../../@shared/components/badge/badge.component";
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { ApprovalConfService } from '../../services/approval-conf.service';

@Component({
  selector: 'app-edit-contract',
  standalone: true,
  imports: [AvatarGroupModule, AvatarModule, ButtonModule, ContractEditorComponent, CommentsComponent, TabMenuModule, AsyncPipe, UserAvatarPipe, TabViewModule, UserChipsComponent, DropdownModule, MenuModule,
    OverlayPanelModule, UserAvatarPipe, CheckboxModule, FormsModule, ConfirmPopupModule, ApprovalWorkflowComponent, FullNamePipe, ContractInsightsComponent, ShareDocComponent, MenubarModule,
    CreateContractComponent, MatDialogModule, ConfirmDialogModule, CommonModule, BadgeComponent, InputIconModule, IconFieldModule, InputTextModule, ChatComponent],
  templateUrl: './edit-contract.component.html',
  styleUrl: './edit-contract.component.scss',
  providers: [ConfirmationService]
})
export class EditContractComponent implements AfterViewInit, OnInit {
  htmlContent: string = ""
  contractId: string = "";
  items: MenuItem[] | undefined;
  labelText: string = 'Send for Approval';
  actionButton: boolean = false;

  @ViewChild('contractEditor') contractEditor!: ContractEditorComponent;

  contract !: IContract;
  connectedUsers$!: BehaviorSubject<IUser[]>
  CONTRACT_STATUS = CONTRACT_STATUS


  users: any[] = []
  filterdUsers: any[] = []

  selectedApprovers: IUser[] = []
  signatoryEmail: string = ""
  signatoryName: string = ""
  signatoryEmailValidation: boolean = false

  approvalConf!: IContractApprovalConf
  approvers: { user: IUser, status: string }[] = []
  signatoryConf!: IContractSignatoryConf
  disableAddSignatories: boolean = false
  signatories: { email: string, name: string, status: string }[] = []
  signatoryType = CONTRACT_SIGNATORY_TYPE.DOCU_SIGN
  CONTRACT_SIGNATORY_TYPE = CONTRACT_SIGNATORY_TYPE
  approver: boolean = false
  currentApprover!: { user: IUser, status: string } | undefined
  currentUser!: IUser | null | undefined
  createdBy: IUser | null | undefined
  flowType = FLOW_TYPE

  role: string = ''
  canView: boolean = false
  canEdit: boolean = false
  canComment: boolean = false
  isOwner: boolean = false
  lastModified: string = ''
  loading: boolean = true

  dialog = inject(MatDialog)
  menuItems: MenuItem[] = [
    {
      label: 'File',
      items: [{
        label: 'New',
        icon: 'pi pi-file-plus',
        command: () => { this.createNewDocument() }
      }, {
        label: 'Open',
        icon: 'pi pi-folder-open',
        command: () => { this.openCreateDialog() }
      }, {
        label: 'Download',
        icon: 'pi pi-download',
        items: [{
          label: 'PDF',
          icon: 'pi pi-file-pdf',
        },
        {
          label: 'Word',
          icon: 'pi pi-file-word',
        }]
      }]
    }
  ]

  constructor(private route: ActivatedRoute, private router: Router, private messageService: MessageService, private contractService: ContractService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private authService: AuthenticationService,
    public approvalService: ApprovalConfService,
    public commentService: CommentService,
    private toast: ToastService,
  ) {
    this.connectedUsers$ = this.contractService.connectedUsers$
    this.authService.currentUser.subscribe(res => {
      this.currentUser = res
    });
    this.route.queryParamMap.subscribe(params => {
      this.htmlContent = params.get('htmlContent') as string;
      this.contractId = params.get('contractId') as string;
    });


    this.items = [
      {
        label: 'Insights',
        icon: 'pi pi-chart-line',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Dashboard', life: 3000 });
        }
      },
      {
        label: 'Approvers',
        icon: 'pi pi-check-square',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Transactions', life: 3000 });
        }
      },
      {
        label: 'Signatories',
        icon: 'pi pi-list',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Products', life: 3000 });
        }
      },
      {
        label: 'Messages',
        icon: 'pi pi-inbox',
        command: () => {
          this.messageService.add({ severity: 'success', summary: 'Selected', detail: 'Messages', life: 3000 });
        }
      }
    ];
  }

  ngOnInit() {
    this.fetchContract()
    this.getLastModified()
    this.getContractApprovers()
    this.getApprovers()
    this.fetchContractSignatories()
  }

  fetchContract() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        this.getRole()
        this.signatoryType = res.data.defaultSignature
        if (!res.data.defaultSignature) {
          this.fetchSignatorySetting()
        }
        this.checkContractStatus()
      })
    } else {
      console.error("No such contract")
    }
  }

  getLastModified() {
    this.loading = true;
    this.fetchContract()
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }

  fetchSignatorySetting() {
    this.contractService.getDefaultSignatory().subscribe(res => {
      if (res.success) {
        this.signatoryType = res.data.defaultSignatory
      }
    })
  }

  getRole() {
    if (this.contract.createdBy == this.currentUser?._id) {
      this.role = CONTRACT_USER_ROLE.OWNER
      this.isOwner = true
      return
    }
    this.role = this.contract.users.find((u: any) => u.user === this.currentUser?._id)?.role ?? CONTRACT_USER_ROLE.VIEWER
    this.canView = this.role === CONTRACT_USER_ROLE.VIEWER
    this.canEdit = this.role === CONTRACT_USER_ROLE.EDITOR
    this.canComment = this.role === CONTRACT_USER_ROLE.COMMENTER
  }

  updateContractName(event: FocusEvent) {
    const target = event.target as HTMLElement;
    const newName = target.textContent?.trim() || '';
    if (this.contract && this.contract.name !== newName) {
      this.contract.name = newName;
      this.updateContract();
    }
  }

  createNewDocument() {
    const form: FormData = new FormData();
    form.append('name', 'Untitled');

    this.contractService.createContract(form).subscribe((res) => {
      if (res.success && res.data) {
        const url = this.router.createUrlTree(['edit-contract'], {
          queryParams: { 'contractId': res.data._id }
        }).toString();


        window.open(url, '_blank');
      }
    });
  }

  openCreateDialog() {
    const _dialogRef = this.dialog.open(CreateContractComponent, {
      disableClose: true,
      minWidth: '40rem',
      data: { contractId: this.contractId }
    });

    _dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.contractEditor.insertHTML(response)
      }
    })
  }
  searchUser(event: any) {
    let searchVal = event.target.value
    if (searchVal == '') {
      this.filterdUsers = this.users
    }
    this.filterdUsers = this.users.filter(users => users.username
      .toLowerCase().includes(searchVal.toLowerCase()))
  }

  updateContract() {
    if (this.contractId && this.contract) {
      this.contractService.updateContract(this.contractId, this.contract).subscribe(
        res => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Contract updated successfully', life: 3000 });
        },
        error => {
          console.error("Error updating contract", error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update contract', life: 3000 });
        }
      );
    } else {
      console.error("No contract to update");
    }
  }


  getApprovers() {
    this.userService.getUsers().subscribe(res => {
      this.users = res.data
      this.users = this.users.filter((user: any) =>
        !this.approvalConf?.approvers.some((approver: any) => approver.user._id === user._id)
      );
      this.filterdUsers = this.users
    })
  }

  ngAfterViewInit(): void {
    if (this.htmlContent) {
      this.contractEditor.insertHTML(this.htmlContent)
    }
    this.approvalChange()
  }

  getIconPath(): string {
    return this.labelText === 'Publish' ? '/icons/rocket.svg' : '/icons/user-check.svg';
  }


  selectApprover(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedApprovers.push(user);
    } else {
      this.selectedApprovers = this.selectedApprovers.filter(u => u !== user);
    }
  }

  getContractApprovers() {
    this.approvalService.fetchContractApprovers(this.contractId).subscribe(res => {
      if (res.data) {
        this.approvalConf = res.data
        this.approver = this.approvalConf.approvers.some((approver: any, index: number) => approver.user._id === this.currentUser?._id && approver.status === CONTRACT_APPROVAL_STATUS.PENDING && this.approvalConf.approvers[index - 1]?.status === CONTRACT_APPROVAL_STATUS.APPROVED || approver.status === CONTRACT_APPROVAL_STATUS.PENDING && index === 0)
        this.currentApprover = this.approvalConf.approvers.find((approver: any) => approver.user._id === this.currentUser?._id)
      }
    });
  }

  addApprovers() {
    let approvers = this.selectedApprovers.map((user: any) => user._id)
    this.approvalService.addApprovers(this.contractId, approvers).subscribe(res => {
      if (res.data) {
        this.toast.success('Approvers Added Successfully');
        this.approvalChange()
        this.selectedApprovers = []
      }
    });
    this.approvalChange()
    this.selectedApprovers = []
  }

  fetchContractSignatories() {
    this.contractService.fetchContractSignatories(this.contractId).subscribe(res => {
      if (res.data) {
        this.signatoryConf = res.data
        switch (this.signatoryConf.status) {
          case CONTRACT_SIGNATORY_STATUS.NOT_YET_STARTED:
          case CONTRACT_SIGNATORY_STATUS.REJECTED:
            this.disableAddSignatories = false
            break;
          default:
            this.disableAddSignatories = true
        }
        this.signatories = this.signatoryConf.signatories.map((signatory) => {
          return {
            email: signatory.email,
            name: signatory.name,
            status: signatory.status,
            signedAttachment: signatory.signedAttachment
          }
        })
      }
    })
  }

  addSignatory() {
    this.signatories.push({
      email: this.signatoryEmail,
      name: this.signatoryName,
      status: CONTRACT_APPROVAL_STATUS.WAITING
    })
    this.signatoryEmail = ""
    this.signatoryName = ""
    this.updateSignatories()
  }

  updateSignatories() {
    this.contractService.updateSignatories(this.contractId, this.signatories).subscribe(res => {
      if (res.success) {
        this.fetchContractSignatories()
        this.checkContractStatus()
      }
    })
  }

  updateName() {
    if (validateEmail(this.signatoryEmail)) this.signatoryEmailValidation = true
    else this.signatoryEmailValidation = false
    this.signatoryName = this.signatoryEmail.substring(0, this.signatoryEmail.lastIndexOf('@') == -1 ? this.signatoryEmail.length : this.signatoryEmail.lastIndexOf('@'));
  }

  goBack() {
    this.router.navigate(['/contract-analyser']);
  }

  async approvalChange() {
    this.checkContractStatus()
    this.getContractApprovers()
    this.getApprovers()
  }

  async signatoryChange(email: string) {
    this.signatories = this.signatories.filter((signatory) => signatory.email !== email)
    this.updateSignatories()
  }

  downloadDocument(uint8Array: Uint8Array) {
    const blob: Blob = new Blob([new Uint8Array(uint8Array)], {
      type: 'application/pdf'
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `sign.pdf`;
    document.body.append(link);
    link.click();
    link.remove();
  }

  async downloadSignedDocument(attachmentId: string) {
    this.contractService.downloadDocument(attachmentId).subscribe(res => {
      if (res.success) {
        this.downloadDocument(res.data.Body.data)
      }
    })
  }

  action() {
    if (this.contract) {
      switch (this.contract.status) {
        case CONTRACT_STATUS.READY_FOR_APPROVAL:
          if (this.approvalConf?.approvers && this.approvalConf.approvers.length > 0) {
            this.approvalService.sendForApproval(this.contractId).subscribe((res) => {
              this.toast.success('Document Sent for Approval Successfully');
              this.approvalChange()
            });
          }
          break;

        case CONTRACT_STATUS.APPROVED:
          // this.contractService.sendForSign(this.contractId).subscribe((res) => {
          //   this.checkContractStatus()
          // });
          break;

        case CONTRACT_STATUS.READY_FOR_SIGNATURE:
          if (this.signatoryConf.signatories.length > 0) {
            this.startSignatoryFlow()
            this.actionButton = true;
          }
          break;
      }
    }
  }

  checkContractStatus() {
    if (this.contractId.length > 0) {
      this.contractService.fetchContract(this.contractId).subscribe((res: { data: IContract; }) => {
        this.contract = res.data
        switch (this.contract?.status) {
          case CONTRACT_STATUS.DRAFT:
            this.labelText = 'Send for Approval';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.READY_FOR_APPROVAL:
            this.labelText = 'Send for Approval';
            this.actionButton = false;
            break;

          case CONTRACT_STATUS.SIGNATURE_IN_PROGRESS:
          case CONTRACT_STATUS.PENDING_APPROVAL:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;

          case CONTRACT_STATUS.APPROVED:
            this.labelText = 'Publish';
            this.actionButton = true;
            break;
          case CONTRACT_STATUS.READY_FOR_SIGNATURE:
            this.labelText = 'Publish';
            if (this.signatoryConf.signatories.length > 0) this.actionButton = false;
            else this.actionButton = true;
            break;
        }
      });
    }

  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    console.log(text)
  }

  startSignatoryFlow() {
    this.contractService.startSignatoryFlow(this.contractId, this.signatoryType).subscribe(res => {
      if (res.success) {
        this.fetchContractSignatories()
        this.checkContractStatus()
        this.actionButton = true
      } else {
        this.actionButton = false
        this.toast.warn(res.message)
      }
    })
  }

  resetSignatoryFlow() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: `This action will stop the current ongoing signatory flow for this document. Do you want to proceed?`,
      accept: () => {
        this.contractService.resetSignatoryFlow(this.signatoryConf._id).subscribe(res => {
          if (res.success) {
            this.fetchContractSignatories()
            this.checkContractStatus()
          }
        })
      },
      reject: () => { }
    });

  }

}
